<template>
  <div class="component-account-plan-assets-update-values">
    <b-container class="report-account-plan-2 bg-light page-wrapper">
      <div class="main-content-wrapper">
        <b-row class="mb-0 pb-0" no-gutters>
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-row>

        <b-row class="mb-0 pb-0" no-gutters>
          <h1 class="text-left">{{ $t(translationPath + 'title_assets') }}</h1>
        </b-row>
        <account-plan-submenu active="assets"></account-plan-submenu>

        <div class="wrapper_white_with_border py-4">

          <account-plan-assets-header></account-plan-assets-header>

          <b-card no-body class="white-tabs-card">
            <b-tabs card class="white-tabs"  @activate-tab="onTabActivate">
              <AssetsMenu>
                <template slot="account-plan-assets-update-values">
                <b-row no-gutters>
                  <b-col cols="12" sm="6" md="8" lg="9">
                    <div class="text-regular" v-html="$t(translationPath + 'asset.update.description')"></div>
                  </b-col>
                  <b-col cols="12" sm="6" md="4" lg="3"  align-self="end">
                    <account-plan-update-values-toggle-columns :options="tableColumnShowOptions" @column-toggle="onColumnToggle" />
                  </b-col>
                </b-row>
                <b-table
                      class="spirecta-simple-table overflow-visible"
                      :key="tableKey"
                      :fields="fieldValues"
                      :items="accounts"
                      :tbody-tr-class="trRowClass"
                      :emptyText="$t('assets_liabilities.assets.no_data')"
                      stacked="md"
                      :busy="busyState"
                      :sort-by="sortBy"
                      :sort-desc="sortDesc"
                      show-empty
                      hover
                      responsive
                      striped
                      :filter="accountsFilter"
                      :filter-included-fields="['title','title_with_code','account_group_title']"
                      :emptyFilteredText ="$t('common.no_filter_result')"
                      @sort-changed="onSortChanged"
                  >
                      <!--loader-->
                      <template v-slot:table-busy>
                        <loader/>
                      </template>
                      <!--loader ends here-->

                      <!-- DATE -->
                      <template v-slot:head(date)="item">
                        {{item.label}}<br />
                        <span class="font-weight-normal">(<b-link href="#" style="color:#ca807c" @click.prevent="onSetDateForAll">{{$t(translationPath+'other_date')}}</b-link>)</span>
                      </template>
                      <!-- END DATE -->

                      <!-- CUSTOM SLOT: FILTER -->
                      <template slot="top-row">
                        <td colspan="9">
                          <div class="d-flex align-items-center">
                            <i class="fa fa-search text-secondary"></i>
                            <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                          </div>
                        </td>
                      </template>

                      <!--TITLE --->
                      <template v-slot:cell(title)="row">
                        <template v-if="row && row.item">
                          <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view/'">
                            {{row.value}}
                          </b-link>
                        </template>
                      </template>

                      <!--TITLE_WITH_CODE --->
                      <template v-slot:cell(title_with_code)="row">
                        <template v-if="row && row.item">
                          <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view/'">
                            {{ row.value }}
                          </b-link>
                        </template>
                      </template>

                      <template v-slot:cell(account_group_title)="row">
                        <b-link :to="'/reports/performance/account-groups/'+row.item.account_group_id">
                          {{ row.value }}
                        </b-link>
                      </template>

                      <!--CURRENT VALUE--->
                      <template v-slot:head(amount)="row">
                        <template v-if="!sDateForAll">{{ row.label }}</template>
                        <template v-else>{{ $t(translationPath + 'asset.value_per', { date: sDateForAll }) }}</template>
                      </template>
                      <template v-slot:cell(amount)="row">
                        <template v-if="row && row.item && row.item.is_account">
                          <span class="text-nowrap">{{ row.value | formatAmountWithDecimal}}</span>
                        </template>
                      </template>

                      <!--LATEST TRANSACTION DATE--->
                      <template v-slot:head(last_transaction_dates)="row">
                        <template v-if="!sDateForAll">{{ row.label }}</template>
                        <template v-else>{{ $t(translationPath + 'asset.last_transaction_before', { date: sDateForAll }) }}</template>
                      </template>
                      <template v-slot:cell(last_transaction_dates)="row">
                        <template v-if="row && row.item && row.item.is_account">
                          {{ row.value }}
                        </template>
                      </template>

                      <!--DATE-->
                      <template v-slot:cell(date)="row">
                        <template v-if="row && row.item && row.item.is_account">
                          <datepicker input-class="form-control text-center" v-model="row.item.date" :language="selectedLang" :typeable="writableDate" :monday-first="true" :format="dateFormatter" @input="(date) => onDateInput(row.item, date)" />
                        </template>
                      </template>

                      <!--NEW VALUE-->
                      <template v-slot:cell(new_value)="row">
                        <template v-if="typeof row !== 'undefined' && typeof row.item !== 'undefined' && row.item.is_account">
                          <currency-input
                            :ref="'CurrencyInputNewValue' + row.item.id"
                            v-model="row.item.new_value"
                            input-class="text-md-right form-control-new-value"
                            @keypress.native="(event) => onNewValueKeyPress(event, row)"
                          />
                        </template>
                      </template>

                      <template v-slot:cell(difference)="row">
                        <template v-if="row && row.item && row.item.is_account">
                          {{ row.item.new_value - row.item.amount | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false, 2) }}
                        </template>
                      </template>

                      <template v-slot:cell(offset_account)="row">
                        <template v-if="row && row.item && row.item.is_account">
                          <custom-select
                          v-model="row.item.default_offset_account"
                          :options="offsetAccount"
                          groupingValues="accounts"
                          groupingLabel="label"
                          :search-placeholder="$t('common.type_to_filter').toString()"
                          :noResultText="$t('common.no_option_found').toString()"
                          :sortGroupLabel="false"
                          :loading="loadingOffsetAccounts"
                          label="label"
                          >
                          </custom-select>
                        </template>
                      </template>

                      <template v-slot:cell(approve)="row">
                        <b-button :disabled="!row.item.is_account || !row.item.default_offset_account" variant="primary" @click="updateItem(row.item)">{{ $t('common.ok') }}</b-button>
                      </template>
                  </b-table>
                  </template>
              </AssetsMenu>
            </b-tabs>
          </b-card>

        </div><!-- END: wrapper_white_with_border -->
      </div><!-- END: main-content-wrapper -->
    </b-container>

    <select-date-modal ref="SelectDateModal" @apply="doSetDateForAll" />
  </div>
</template>

<script>
import AccountPlanTabMixin from './AccountPlanTabMixin'
import CurrencyInput from '@/components/common/CurrencyInput'
import axios from 'axios'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import formatAmount from '@/assets/filters/formatAmount'
import formatAmountWithDecimal from '@/assets/filters/formatAmountWithDecimal'
import { mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import CustomSelect from '@/components/common/CustomSelect'
import Loader from '@/components/common/Loader'
import _ from 'lodash'
import AssetsMenu from './submenu/AssetsMenu'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'
import SelectDateModal from './SelectDateModal'
import AccountPlanUpdateValuesToggleColumns from './AccountPlanUpdateValuesToggleColumns'

export default {
  name: 'UpdateMultipleAssets',
  components: {
    Datepicker,
    CurrencyInput,
    CustomSelect,
    Loader,
    AssetsMenu,
    AccountPlanAssetsHeader,
    SelectDateModal,
    AccountPlanUpdateValuesToggleColumns
  },
  mixins: [AccountPlanTabMixin],
  data () {
    return {
      accounts: [],
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      writableDate: true,
      loadingOffsetAccounts: false,
      offsetAccount: [],
      accountsFilter: '',
      translationPath: 'reports.other.account_plan.',
      translationPathUpdateValue: 'common.',
      busyState: false,
      tableKey: 0, // Hack to force vuejs to rerender table
      sortBy: 'last_transaction_dates',
      sortDesc: false,
      accountDatesToSet: {},
      sDateForAll: null,
      tableColumnShowOptions: {
        account_group_title: true,
        last_transaction_dates: true,
        date: true,
        difference: false,
        offset_account: false
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      const fields = []
      fields.push({ key: this.currentCOA.prefix_account_title_with_code ? 'title_with_code' : 'title', label: this.$t(this.translationPathUpdateValue + 'title'), sortable: true, class: 'td-title' })
      if (this.tableColumnShowOptions.account_group_title) {
        fields.push({ key: 'account_group_title', label: this.$t(this.translationPath + 'asset.group'), sortable: true })
      }
      fields.push({ key: 'amount', label: this.$t(this.translationPath + 'asset.current_value'), sortable: false, formatter: 'AmountValueFormatter', class: 'text-left text-md-right td-last_value' })
      if (this.tableColumnShowOptions.last_transaction_dates) {
        fields.push({ key: 'last_transaction_dates', label: this.$t('assets_liabilities.common.last_update'), sortable: true, class: 'text-left text-md-right  td-last_transaction_date' })
      }
      if (this.tableColumnShowOptions.date) {
        fields.push({ key: 'date', label: this.$t(this.translationPathUpdateValue + 'date'), sortable: false, class: 'text-left text-md-right td-new-date' })
      }
      fields.push({ key: 'new_value', label: this.$t(this.translationPath + 'asset.update_value'), sortable: false, class: 'text-left text-md-right td-new-value' })
      if (this.tableColumnShowOptions.difference) {
        fields.push({ key: 'difference', label: this.$t(this.translationPathUpdateValue + 'difference'), sortable: false, class: 'text-left text-md-right' })
      }
      if (this.tableColumnShowOptions.offset_account) {
        fields.push({ key: 'offset_account', label: this.$t(this.translationPath + 'asset.offset_account'), sortable: false })
      }
      fields.push({ key: 'approve', label: '', sortable: false, class: 'text-left text-md-right' })

      return fields
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    local () {
      return this.currentUser.default_lang
    }
  },
  created () {
    this.loadingOffsetAccounts = true
    this.busyState = true

    if (window.localStorage.AccountPlanAssetsUpdateValues) {
      const pageData = JSON.parse(window.localStorage.AccountPlanAssetsUpdateValues)
      this.sortBy = pageData.sortBy || this.sortBy
      this.sortDesc = pageData.sortDesc || this.sortDesc
    }

    this.fetchOffsetAccounts('income,expense,liability')
      .then((responseData) => {
        this.loadingOffsetAccounts = false
        const incomeGroups = responseData.filter(item => item.type === 'income').map(items => {
          const label = (this.$te(items.type) ? this.$t(items.type) : items.type) + ' / ' + items.title
          const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
            const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
            return Object.assign(accountItem, { label: accountLabel })
          })
          return Object.assign({}, items, { accounts: accounts, label: label })
        })
        const expenseGroups = responseData.filter(item => item.type === 'expense').map(items => {
          const label = (this.$te(items.type) ? this.$t(items.type) : items.type) + ' / ' + items.title
          const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
            const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
            return Object.assign(accountItem, { label: accountLabel })
          })
          return Object.assign({}, items, { accounts: accounts, label: label })
        })
        const liabilityGroups = responseData.filter(item => item.type === 'liability').map(items => {
          const label = (this.$te(items.type) ? this.$t(items.type) : items.type) + ' / ' + items.title
          const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
            const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
            return Object.assign(accountItem, { label: accountLabel })
          })
          return Object.assign({}, items, { accounts: accounts, label: label })
        })
        this.offsetAccount = [...incomeGroups, ...expenseGroups, ...liabilityGroups].filter(items => items.accounts.length !== 0)
        return true
      })
      .then(() => {
        this.loadAccounts('asset')
      })
  },
  methods: {
    onNewValueKeyPress (event, row) {
      if (event.keyCode === 13 && row.item.default_offset_account) {
        this.updateItem(row.item)
          .then(() => {
            const els = document.getElementsByClassName('form-control-new-value')
            const elIndex = row.index === els.length - 1 ? 0 : row.index + 1
            els[elIndex].focus()
          })
      }
    },
    /**
     * Sends request to update account.
     * Saves selected offset account as default offset account.
     */
    async updateAccountDefaults (item) {
      return new Promise((resolve, reject) => {
        const putData = {
          default_offset_account_id: item.default_offset_account.id
        }

        axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/${item.id}`, putData)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /**
     * Sends request to update asset value.
     */
    async updateAssetValue (item) {
      return new Promise((resolve, reject) => {
        const postData = this.formatPostData(item)

        let isContainNullAmount = false
        for (const i in postData) {
          if (isContainNullAmount) {
            break
          }
          for (const j in postData[i].entries) {
            if (!postData[i].entries[j].amount) {
              isContainNullAmount = true
              break
            }
          }
        }
        if (isContainNullAmount) {
          reject(Error('Zero amount in transaction'))
        } else {
          axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, { transactions: postData })
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              reject(err)
            })
        }
      })
    },
    async updateItem (item) {
      return new Promise((resolve, reject) => {
        Promise.all([
          this.updateAssetValue(item),
          this.updateAccountDefaults(item)
        ])
          .then(() => {
            this.$bvToast.toast(this.$t(this.translationPath + 'update_values.toast.success_description').toString(), {
              title: this.$t(this.translationPath + 'update_values.toast.success_title').toString(),
              variant: 'success',
              solid: true,
              autoHideDelay: 3000
            })
            this.tableKey++ // Force vuejs to rerender table (can be view bugs instead in focused input fields)
            item.amount = item.new_value.toString()
            const splittedAmount = item.amount.split('.')
            if (splittedAmount.length === 1) {
              item.amount += '.00'
            } else if (splittedAmount[1].length === 1) {
              item.amount += '0'
            }
            item.last_transaction_dates = item.date
            if (typeof item.last_transaction_dates === 'object') {
              item.last_transaction_dates = item.last_transaction_dates.toISOString().substr(0, 10)
            }
            resolve(true)
          })
          .catch(err => {
            if (err.message === 'Zero amount in transaction') {
              this.$bvToast.toast(this.$t(this.translationPath + 'update_values.toast.fail_description_zero_amount').toString(), {
                title: this.$t(this.translationPath + 'update_values.toast.fail_title').toString(),
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000
              })
              resolve(true)
            } else {
              console.error(err)
              reject(err)
            }
          })
      })
    },
    getFormattedDate (iDate) {
      const oDate = new Date(iDate)
      const sMonth = (oDate.getMonth() + 1).toString().padStart(2, 0)
      const sDay = oDate.getDate().toString().padStart(2, 0)
      return oDate.getFullYear() + '-' + sMonth + '-' + sDay
    },
    formatPostData (item) {
      const itemNewValue = item.new_value || 0
      const calculateDifference = (parseFloat(itemNewValue) - parseFloat(item.amount))
      let EntryType = item.type === 'asset' ? 'cr' : 'dr'
      let OffsetEntryType = item.type === 'asset' ? 'dr' : 'cr'
      if (calculateDifference > 0) {
        EntryType = item.type === 'asset' ? 'dr' : 'cr'
        OffsetEntryType = item.type === 'asset' ? 'cr' : 'dr'
      }
      return [
        {
          title: item.title,
          date: this.getFormattedDate(item.date),
          type: 'transfer',
          description: item.description,
          entries: [
            {
              transaction_id: 0,
              account_id: item.id ? Number(item.id) : 0,
              offset_account_id: item.default_offset_account ? Number(item.default_offset_account.id) : 0,
              dctype: EntryType,
              amount: Math.abs(calculateDifference),
              active_percentage: null
            },
            {
              transaction_id: 0,
              account_id: item.default_offset_account ? Number(item.default_offset_account.id) : 0,
              offset_account_id: item.id ? Number(item.id) : 0,
              dctype: OffsetEntryType,
              amount: Math.abs(calculateDifference),
              active_percentage: null
            }
          ]
        }
      ]
    },
    trRowClass (item) {
      if (!item) return true
      if (item.is_account) return true
      return 'table-account-group'
    },
    fetchOffsetAccounts (fetchType) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?is_capital_account_or_networth=1&log=AccountPlanAssetsUpdateValues`)
        .then(response => response.data.data)
        .then(responseData => {
          console.log(responseData)
          return responseData
        })
        .catch(err => {
          console.error(err)
          return []
        })
    },
    async loadAccounts (accountTypes, date = null) {
      let sApiUrl = `${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=${accountTypes}&include_balance_amounts=1`
      if (date) {
        sApiUrl += `&balance_amounts_per_date=${date}`
      }
      await axios.get(sApiUrl)
        .then((response) => {
          return response.data.data || null
        })
        .then((apiData) => {
          const Accounts = []
          const tmpOffsetAccount = _.flattenDeep([...this.offsetAccount].map((el) => el.accounts))
          const offsetAccounts = {}

          for (const index in tmpOffsetAccount) {
            offsetAccounts[tmpOffsetAccount[index].id] = tmpOffsetAccount[index]
          }

          for (const iGroupIndexNotId in apiData) {
            for (const iAccountIndexNotId in apiData[iGroupIndexNotId].accounts) {
              const oTmpAccount = Object.assign({}, apiData[iGroupIndexNotId].accounts[iAccountIndexNotId], {
                is_account: true,
                is_group_account: false,
                new_value: Number(apiData[iGroupIndexNotId].accounts[iAccountIndexNotId].amount),
                last_transaction_dates: apiData[iGroupIndexNotId].accounts[iAccountIndexNotId].latest_transaction_date,
                date: this.accountDatesToSet[apiData[iGroupIndexNotId].accounts[iAccountIndexNotId].id] || this.$moment().format('YYYY-MM-DD'),
                default_offset_account: offsetAccounts[apiData[iGroupIndexNotId].accounts[iAccountIndexNotId].default_offset_account_id],
                account_group_id: apiData[iGroupIndexNotId].id,
                account_group_title: this.currentCOA.prefix_account_title_with_code ? apiData[iGroupIndexNotId].title_with_code : apiData[iGroupIndexNotId].title
              })

              if (!oTmpAccount.default_offset_account) {
                oTmpAccount.default_offset_account = offsetAccounts[this.currentCOA.networth_account_id]
              }

              // Don't include accounts that you are not allowed to directly update
              if (oTmpAccount.allow_update === 1) {
                Accounts.push(oTmpAccount)
              }
            }
          }
          this.accounts = Accounts
          this.busyState = false
        })
        .catch((error) => {
          this.busyState = false
          console.error(error)
        })
    },
    AmountValueFormatter (value) {
      return formatAmount(
        value,
        this.currentCOA.locale,
        this.currentCOA.currency,
        this.currentCOA.currency_iso,
        false,
        2
      )
    },
    dateFormatter (date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
    onSortChanged (eventData) {
      this.sortBy = eventData.sortBy
      this.sortDesc = eventData.sortDesc
      window.localStorage.AccountPlanAssetsUpdateValues = JSON.stringify({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      })
      this.rerenderCurrencyInputs()
    },
    onSetDateForAll () {
      this.$refs.SelectDateModal.show()
    },
    async doSetDateForAll (date) {
      const sDate = this.dateFormatter(date)
      this.sDateForAll = sDate
      await this.loadAccounts('asset', sDate)
      this.accountDatesToSet = {}
      this.accounts.map(account => {
        account.date = sDate
        this.accountDatesToSet[account.id] = sDate
      })
      this.tableKey++
    },
    onDateInput (item, date) {
      this.accountDatesToSet[item.id] = this.dateFormatter(date)
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/meta?account_id=${item.id}&end_date=${date.toISOString().substr(0, 10)}`)
        .then(response => {
          item.amount = 0
          item.new_value = 0
          if (response.data.data.amount_sum != null) {
            item.amount = response.data.data.amount_sum
            item.new_value = Number(response.data.data.amount_sum)
          }

          const splittedAmount = item.amount.toString().split('.')
          if (splittedAmount.length === 1) {
            item.amount = item.amount + '.00'
          } else if (splittedAmount[1].length === 1) {
            item.amount += '0'
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    onColumnToggle (column) {
      this.tableColumnShowOptions[column] = !this.tableColumnShowOptions[column]
    }
  },
  filters: {
    displayAccountTitle,
    formatAmount,
    formatAmountWithDecimal
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
.component-account-plan-assets-update-values{
  width:100%;
  .datepicker-all-wrap {
    .form-control {
      width: auto;
      display: inline-block;
    }
    .vdp-datepicker {
      display: inline-block;
    }
  }
  .spirecta-simple-table {
    td,th{
      font-size:90%;
    }
    td.td-title{
      font-weight: 400;
    }
    td.td-last_transaction_date{
    }
    td.td-new-date{
      min-width:100px;
    }
    @media screen and (min-width: 768px) {
      td.td-new-date {
        max-width:140px;
      }
    }
    td.td-new-date .vdp-datepicker input{
      padding-left:0px;
      padding-right:0px;
    }
  }
}
</style>
