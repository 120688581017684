export default function formatAmountWithDecimal (value) {
  const splitTextAmount = value.split('.')
  let amountValue = null
  if (splitTextAmount[0].length <= 3) {
    amountValue = splitTextAmount[0]
  } else {
    const amountArrayValue = splitTextAmount[0].split('').reverse()
    let amountVal = ''
    for (let i = amountArrayValue.length - 1; i >= 0; i--) {
      amountVal = amountArrayValue[i] + amountVal
      if (i !== 0 && i % 3 === 0) {
        amountVal = ' ' + amountVal
      }
    }
    amountValue = amountVal.split(' ').reverse().map((item) => item.split('').reverse().join('')).join(' ')
  }
  return splitTextAmount.length === 2 ? (amountValue + '.' + splitTextAmount[1]) : amountValue
}
