<template>
  <div class="spirecta-toggle-wrapper">
      <b-dropdown right
                  :text="$t('common.toggle_columns')"
                  variant='outline-secondary'
                  class="float-right text-regular mr-3 toggleColumnWrapper"
                  size="sm"
      >
        <b-dropdown-item @click="toggleColumns('account_group_title')" :class="{ active: options.account_group_title }">{{ $t(translationPath + 'asset.group') }}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('last_transaction_dates')" :class="{ active: options.last_transaction_dates }">{{ $t('assets_liabilities.common.last_update') }}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('date')" :class="{ active: options.date }">{{ $t('common.date') }}</b-dropdown-item>
        <b-dropdown-item @click="toggleColumns('difference')" :class="{ active: options.difference }">{{ $t('common.difference') }}</b-dropdown-item>
        <b-dropdown-item v-if="Object.prototype.hasOwnProperty.call(options, 'offset_account')" @click="toggleColumns('offset_account')" :class="{ active: options.offset_account }">{{ $t(translationPath + 'asset.offset_account') }}</b-dropdown-item>
      </b-dropdown>

  </div>
</template>

<script>
export default {
  name: 'AccountPlanUpdateValuesToggleColumns',
  props: {
    options: {
      type: Object,
      default: function () {
        return {
          account_group_title: true,
          last_transaction_dates: true,
          date: true,
          difference: true,
          offset_account: true
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  },
  methods: {
    toggleColumns (columnToDisplay) {
      this.$emit('column-toggle', columnToDisplay)
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
</style>
