<template>
  <b-modal
    centered
    ref="SelectDateModal"
    modal-class="spirecta-modal select-date-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title') }}
    </template>
    <p class="mb-0 font-weight-lighter">{{ $t(translationPath + 'description') }}</p>
    <div class="mt-4">
      <datepicker
        input-class="form-control text-center mr-2"
        inline
        v-model="date"
        :language="selectedLang"
        :typeable="true"
        :monday-first="true"
      />
    </div>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t('common.no_cancel') }}</b-button>
      <b-button variant="primary" @click="onApply">{{ $t(translationPath + 'btn_apply') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'

export default {
  name: 'SelectDateModal',
  components: { Datepicker },
  data () {
    return {
      translationPath: 'reports.other.account_plan.modal_select_date.',
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      date: new Date()
    }
  },
  computed: {
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.SelectDateModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.SelectDateModal.hide()
      this.$emit('hide')
    },
    onCancel () {
      this.$refs.SelectDateModal.hide()
      this.$emit('cancel')
    },
    onApply () {
      this.$emit('apply', this.date)
      this.hide()
    }
  }
}
</script>

<style lang="scss">
.select-date-modal {
  .vdp-datepicker__calendar {
    width: auto !important;
  }
}
</style>
